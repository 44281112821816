// JobView.js
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import API_BASE_URL from '../../constants/apiConfig';
import EditButton from '../shared/EditButton';
import ReadOnlyJobView from './ReadOnlyJobView';
import EditableJobView from './EditableJobView';
import './JobView.css';
import { formatPhoneNumberForE164, formatPhoneNumberForDisplay } from '../shared/FormatPhoneNumber';

const JobView = ({ isNewJob = false }) => {
  const navigate = useNavigate();
  const { jobName } = useParams();
  const user = useSelector((state) => state.user.user);
  
  const [jobData, setJobData] = useState(null);
  const [editedJobData, setEditedJobData] = useState({
    id: '',
    jobName: '',
    generalContractor: '',
    status: '',
    emails: [],
    requireSuperintendentApproval: false,
    superintendentName: '',
    superintendentPhoneNumber: '',
  });
  const [isEditable, setIsEditable] = useState(isNewJob);
  const [changeOrders, setChangeOrders] = useState([]);
  const [errors, setErrors] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [customerRequiresSuperintendentApproval, setCustomerRequiresSuperintendentApproval] = useState(false);

  const formatJobName = (name) => name.trim().replace(/-+/g, ' ');
  const formattedJobName = jobName ? formatJobName(jobName) : '';

  const fetchJobDetails = useCallback(async () => {
    if (user?.customer_id && formattedJobName) {
      try {
        const response = await axios.get(`${API_BASE_URL}/job`, {
          params: { job_name: formattedJobName, customer_id: user.customer_id },
        });
        // Format the phone number for display
        const formattedData = {
          ...response.data,
          superintendentPhoneNumber: formatPhoneNumberForDisplay(response.data.superintendentPhoneNumber)
        };
        setJobData(formattedData);
        setCustomerRequiresSuperintendentApproval(response.data.customerRequiresSuperintendentApproval);
        setErrors((prev) => ({ ...prev, jobDetails: null }));
      } catch (error) {
        console.error('Error fetching job details:', error);
        setErrors((prev) => ({ ...prev, jobDetails: error }));
      }
    }
  }, [user, formattedJobName]);

  const fetchChangeOrders = useCallback(async () => {
    if (user?.customer_id && formattedJobName) {
      try {
        const params = {
          customer_id: user.customer_id,
          job_name: formattedJobName,
        };
        const response = await axios.get(`${API_BASE_URL}/job/change-orders`, { params });
        if (response.data.success) {
          setChangeOrders(response.data.data);
          setErrors((prev) => ({ ...prev, changeOrders: null }));
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        if (error.response?.status === 404) {
          setChangeOrders([]);
          setErrors((prev) => ({ ...prev, changeOrders: null }));
        } else {
          setErrors((prev) => ({ ...prev, changeOrders: error }));
        }
      }
    }
  }, [user, formattedJobName]);  

  useEffect(() => {
    if (!isNewJob) {
      fetchJobDetails();
      fetchChangeOrders();
    }
  }, [isNewJob, fetchJobDetails, fetchChangeOrders]);

  const handleEnterEditMode = () => {
    setEditedJobData({
      id: jobData?.id || '',
      jobName: jobData?.jobName || '',
      generalContractor: jobData?.generalContractor || '',
      status: jobData?.status || '',
      emails: jobData?.emails || [],
      requireSuperintendentApproval: jobData?.requireSuperintendentApproval || false,
      superintendentName: jobData?.superintendentName || '',
      superintendentPhoneNumber: jobData?.superintendentPhoneNumber || '',
    });
    setIsEditable(true);
  };

  const handleCancelEditMode = () => {
    setEditedJobData({
      id: '',
      jobName: '',
      generalContractor: '',
      status: '',
      emails: [],
      requireSuperintendentApproval: false,
      superintendentName: '',
      superintendentPhoneNumber: '',
    });
    setIsEditable(false);
    if (isNewJob) navigate('/jobs');
  };

  const handleSaveEditMode = async () => {
    if (!isFormValid) {
      // Show an error message or return early
      return;
    }
  
    const trimData = (data) => ({
      ...data,
      jobName: data.jobName.trim(),
      generalContractor: data.generalContractor.trim(),
      emails: data.emails.map(email => email.trim()),
      superintendentName: data.requireSuperintendentApproval ? (data.superintendentName ? data.superintendentName.trim() : null) : null,
      superintendentPhoneNumber: data.requireSuperintendentApproval ? (data.superintendentPhoneNumber ? formatPhoneNumberForE164(data.superintendentPhoneNumber) : null) : null,
    });
  
    const trimmedData = trimData(editedJobData);
  
    // Check if the phone number is valid
    if (trimmedData.requireSuperintendentApproval && (!trimmedData.superintendentPhoneNumber || trimmedData.superintendentPhoneNumber.length !== 12)) {
      setErrors((prev) => ({ ...prev, update: new Error('Invalid superintendent phone number') }));
      return;
    }
  
    const formattedJobName = formatJobName(trimmedData.jobName);
  
    setIsUpdating(true);
    try {
      const endpoint = isNewJob ? '/job/create' : '/job/update';
      await axios.post(`${API_BASE_URL}${endpoint}`, {
        ...trimmedData,
        customer_id: user.customer_id,
      });
      setIsUpdating(false);
      setErrors((prev) => ({ ...prev, update: null }));
      if (isNewJob) {
        setIsEditable(false);
        navigate(`/job/${formattedJobName}`);
      } else {
        setIsEditable(false);
        setJobData(trimmedData);
      }
    } catch (error) {
      console.error('Error updating job details:', error);
      setErrors((prev) => ({ ...prev, update: error }));
      setIsUpdating(false);
    }
  };
  
  if (errors.jobDetails) {
    return <div className="alert alert-danger" role="alert">Error loading job details: {errors.jobDetails.message}</div>;
  }

  if (errors.update) {
    return <div className="alert alert-danger" role="alert">Error updating job details: {errors.update.message}</div>;
  }

  if (isUpdating) {
    return <div>Updating job details...</div>;
  }

  if (!isNewJob && !jobData && !isUpdating) {
    return <div>Loading job details...</div>;
  }

  return (
    <div className="container mt-5 jobview-container job-view">
      <div className="card shadow">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2 className="card-title">Job Details</h2>
            <EditButton
              isEditable={isEditable}
              onEdit={handleEnterEditMode}
              onSave={handleSaveEditMode}
              onCancel={handleCancelEditMode}
              isDisabled={isEditable && !isFormValid}
            />
          </div>
  
          {isEditable ? (
            <EditableJobView
              editedJobData={editedJobData}
              setEditedJobData={setEditedJobData}
              setIsFormValid={setIsFormValid}
              customerRequiresSuperintendentApproval={customerRequiresSuperintendentApproval}
            />
          ) : (
            <ReadOnlyJobView
              jobData={jobData}
              isNewJob={isNewJob}
              changeOrders={changeOrders}
              errors={errors}
              customerRequiresSuperintendentApproval={customerRequiresSuperintendentApproval}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default JobView;