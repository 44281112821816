import React from 'react';
import { useSelector } from 'react-redux';
import './Totals.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Totals = () => {
  const { changeOrderDetails } = useSelector((state) => state.changeOrder);
  const { profit_and_overhead_rate, tax_rate, categories } = changeOrderDetails.bigDict || {};

  const calculateCosts = () => {
    let subtotal = 0;
    let materialsCost = 0;

    if (categories && typeof categories === 'object') {
      Object.entries(categories).forEach(([categoryName, category]) => {
        if (category && typeof category === 'object') {
          const categoryTotal = Object.values(category).reduce((catTotal, item) => {
            return catTotal + ((item.quantity || 0) * parseFloat(item.cost_per_unit || 0));
          }, 0);
          
          subtotal += categoryTotal;
          if (categoryName.toLowerCase() === 'materials') {
            materialsCost = categoryTotal;
          }
        }
      });
    }

    const taxAmount = materialsCost * (tax_rate || 0);
    const totalWithTax = subtotal + taxAmount;
    const poAmount = totalWithTax * (profit_and_overhead_rate || 0);
    const finalTotalCost = totalWithTax + poAmount;

    return { subtotal, taxAmount, poAmount, finalTotalCost };
  };

  const { subtotal, taxAmount, poAmount, finalTotalCost } = calculateCosts();

  const formatCurrency = (amount) => {
    amount = amount || 0;
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const profitAndOverheadDisplay = profit_and_overhead_rate ? `${(profit_and_overhead_rate * 100).toFixed(2)}%` : '0%';
  const taxDisplay = tax_rate ? `${(tax_rate * 100).toFixed(2)}%` : '0%';

  return (
    <div className="co-totals">
      <table className="table co-totals__table">
        <tbody>
        <tr className="co-totals__subtotals-row">
            <th>Subtotals</th>
            <td>{formatCurrency(subtotal)}</td>
          </tr>
          <tr>
            <th>Tax on materials ({taxDisplay})</th>
            <td>{formatCurrency(taxAmount)}</td>
          </tr>
          <tr className="co-totals__profit-overhead-row">
            <th>Profit & Overhead ({profitAndOverheadDisplay})</th>
            <td>{formatCurrency(poAmount)}</td>
          </tr>
          <tr className="co-totals__total-row">
            <th>Total Cost</th>
            <td>{formatCurrency(finalTotalCost)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Totals;