// useFilteredChangeOrderMetrics.js
import { useState, useMemo } from 'react';

const useFilteredChangeOrderMetrics = (allChangeOrders) => {
  const [timeRange, setTimeRange] = useState('all'); // Default to 'all' time range

  // Filter the change orders based on the selected time range
  const filteredOrders = useMemo(() => {
    if (timeRange === 'all') return allChangeOrders; // If 'all' is selected, return all orders

    const maxDaysOld = parseInt(timeRange); // Parse the time range (e.g., 30 for "Last 30 Days")
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - maxDaysOld); // Calculate the cutoff date

    return allChangeOrders.filter(order => {
      const orderDate = order.createdAt ? new Date(order.createdAt) : null;
      if (!orderDate) return false; // Exclude orders without a valid date
      return orderDate >= cutoffDate; // Filter orders within the selected time range
    });
  }, [allChangeOrders, timeRange]);

  // Compute metrics for filtered change orders
  const filteredChangeOrdersMetrics = useMemo(() => {
    const metrics = {
      Total: { count: 0, totalValue: 0 },
      Unexecuted: { count: 0, totalValue: 0 },
      Executed: { count: 0, totalValue: 0 },
      Cancelled: { count: 0, totalValue: 0 },
    };

    filteredOrders.forEach(order => {
      if (order.status !== 'Incomplete' && order.status !== 'Deleted') {
        const value = parseFloat(order.amount) || 0; // Parse the order amount

        metrics.Total.count++;
        metrics.Total.totalValue += value;

        // Update metrics based on the order's status
        if (order.status === 'Unexecuted') {
          metrics.Unexecuted.count++;
          metrics.Unexecuted.totalValue += value;
        } else if (order.status === 'Executed') {
          metrics.Executed.count++;
          metrics.Executed.totalValue += value;
        } else if (order.status === 'Cancelled' || order.status === 'Canceled') {
          metrics.Cancelled.count++;
          metrics.Cancelled.totalValue += value;
        }
      }
    });

    return metrics; // Return the computed metrics
  }, [filteredOrders]);

  // Prepare pie chart data based on the computed metrics
  const pieChartData = useMemo(() => {
    if (filteredChangeOrdersMetrics.Total.count === 0) {
      // Return placeholder values if no change orders exist
      return [
        { name: 'Unexecuted', value: 1, count: 0, totalValue: '0.00' },
        { name: 'Executed', value: 1, count: 0, totalValue: '0.00' },
        { name: 'Cancelled', value: 1, count: 0, totalValue: '0.00' },
      ];
    }

    // Create pie chart data based on the filtered Metrics
    return Object.entries(filteredChangeOrdersMetrics)
      .filter(([key]) => key !== 'Total')
      .map(([name, { count, totalValue }]) => ({
        name,
        value: count, // Number of change orders in this category
        count: count,
        totalValue: totalValue.toFixed(2), // Format total value
      }));
  }, [filteredChangeOrdersMetrics]);

  // Handle time range change
  const handleTimeRangeChange = (newRange) => {
    setTimeRange(newRange); // Update the time range when the user selects a different option
  };

  return {
    timeRange,                    // Current selected time range
    filteredChangeOrdersMetrics,  // Computed metrics for filtered orders
    pieChartData,                 // Data for the pie chart visualization
    handleTimeRangeChange,        // Function to update the time range
    filteredOrders,               // List of filtered orders
  };
};

export default useFilteredChangeOrderMetrics;