import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateEditValues } from '../redux/changeOrderActions';
import './Totals.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const EditableTotals = () => {
  const dispatch = useDispatch();
  const { editValues } = useSelector((state) => state.changeOrder);
  const { categories, profit_and_overhead_rate, tax_rate } = editValues;

  const calculateCosts = () => {
    let subtotal = 0;
    let materialsCost = 0;

    Object.entries(categories).forEach(([categoryName, category]) => {
      const categoryTotal = Object.values(category).reduce((catTotal, item) => {
        return catTotal + ((item.quantity || 0) * parseFloat(item.cost_per_unit || 0));
      }, 0);
      
      subtotal += categoryTotal;
      if (categoryName.toLowerCase() === 'materials') {
        materialsCost = categoryTotal;
      }
    });

    const taxAmount = materialsCost * (tax_rate || 0);
    const totalWithTax = subtotal + taxAmount;

    return { subtotal, taxAmount, totalWithTax };
  };

  const { subtotal, taxAmount, totalWithTax } = calculateCosts();

  const wholeNumberPandO = Math.round((profit_and_overhead_rate || 0) * 10000) / 100;
  const wholeNumberTax = Math.round((tax_rate || 0) * 10000) / 100;

  const formatCurrency = (amount) => {
    amount = amount || 0;
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const handleProfitAndOverheadChange = (value) => {
    const parsedValue = parseFloat(value);
    dispatch(updateEditValues({
      ...editValues,
      profit_and_overhead_rate: parsedValue / 100,
    }));
  };

  const handleTaxRateChange = (value) => {
    const parsedValue = parseFloat(value);
    dispatch(updateEditValues({
      ...editValues,
      tax_rate: parsedValue / 100,
    }));
  };

  // Calculate the P&O amount
  const poAmount = totalWithTax * (profit_and_overhead_rate || 0);

  // Calculate the final total cost
  const finalTotalCost = totalWithTax + poAmount;

  return (
    <div className="co-totals">
      <table className="table co-totals__table">
        <tbody>
          <tr className="co-totals__subtotals-row">
            <th>Subtotals</th>
            <td>{formatCurrency(subtotal)}</td>
          </tr>
          <tr>
            <th>
              Tax on materials
              <input
                type="number"
                value={wholeNumberTax}
                onChange={(e) => handleTaxRateChange(e.target.value)}
                className="form-control co-totals__tax-input"
                min="0"
                max="100"
                step="0.01"
              />
              %
            </th>
            <td>{formatCurrency(taxAmount)}</td>
          </tr>
          <tr className="co-totals__profit-overhead-row">
            <th>
              Profit & Overhead
              <input
                type="number"
                value={wholeNumberPandO}
                onChange={(e) => handleProfitAndOverheadChange(e.target.value)}
                className="form-control co-totals__profit-overhead-input"
                min="0"
                max="100"
                step="0.01"
              />
              %
            </th>
            <td>{formatCurrency(poAmount)}</td>
          </tr>
          <tr className="co-totals__total-row">
            <th>Total Cost</th>
            <td>{formatCurrency(finalTotalCost)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EditableTotals;