// SalesSignup.js
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import API_BASE_URL from '../../constants/apiConfig';
import './SalesSignup.css';

const STRIPE_TEST_PUBLISHABLE_KEY = 'pk_test_51Ohwg7BcQcTjfkgqy6K2VMS1OlrCB5AtSX60bcGaQ6v0W3nGW2o3tVj4DpgoipaZqFJIJEmNaxpTNjt4kGuXU5jR009ohB8sPY';
const STRIPE_LIVE_PUBLISHABLE_KEY = 'pk_live_51Ohwg7BcQcTjfkgqKbk7HoF8mvVyhV9s7tat0GE7IyaUltMwHg9IsDdswqvdqBGrSOxDh9Kv3a0r7lGWuKpU9FN400b7gH20Bu';

const SalesSignupForm = ({ isLiveAccount }) => {
  const [companyName, setCompanyName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [planType, setPlanType] = useState('');
  const [planId, setPlanId] = useState('');
  const [isCustomPlan, setIsCustomPlan] = useState(false);
  const [customCredits, setCustomCredits] = useState('');
  const [changeOrderPrice, setChangeOrderPrice] = useState('');

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/sales-signup-plans?isLiveAccount=${isLiveAccount}`);
        if (!response.ok) {
          throw new Error('Failed to fetch plans');
        }
        const data = await response.json();
        setPlans(data);
      } catch (error) {
        console.error('Error fetching plans:', error);
        setErrorMessage('Failed to load plans. Please try again.');
      }
    };
  
    fetchPlans();
  }, [isLiveAccount]);

  const formatPhoneNumber = (input) => {
    const cleaned = input.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return '(' + match[1] + (match[2] ? ') ' : '') + match[2] + (match[3] ? '-' + match[3] : '');
    }
    return input;
  };

  const handlePhoneChange = (e) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedNumber);
  };

  const calculateCustomPlanPrice = (credits, pricePerCredit) => {
    const creditsNum = parseFloat(credits);
    const priceNum = parseFloat(pricePerCredit);
    if (isNaN(creditsNum) || isNaN(priceNum)) {
      return 0;
    }
    return creditsNum * priceNum;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    if (!stripe || !elements) {
      setErrorMessage('Stripe has not loaded. Please try again.');
      setIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        throw error;
      }

      const backendFormattedPhone = '+1' + phoneNumber.replace(/\D/g, '');

      let planData = null;
      if (planType === 'custom') {
        if (!customCredits || !changeOrderPrice) {
          throw new Error('Please provide both custom credits and change order price for custom plan.');
        }
        planData = {
          type: 'custom',
          credits: parseInt(customCredits),
          changeOrderPrice: parseFloat(changeOrderPrice)
        };
      } else if (planType === 'predefined') {
        if (!planId) {
          throw new Error('Please select a plan.');
        }
        planData = {
          type: 'predefined',
          id: planId
        };
      } else {
        throw new Error('Please select a plan.');
      }

      const response = await fetch(`${API_BASE_URL}/api/sales-signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          companyName,
          firstName,
          lastName,
          email,
          phoneNumber: backendFormattedPhone,
          plan: planData,
          paymentMethodId: paymentMethod.id,
          isLiveAccount,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Signup failed');
      }

      await response.json();
      setSuccessMessage('Customer successfully signed up!');
      // Reset form fields
      setCompanyName('');
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhoneNumber('');
      setPlanType('');
      setPlanId('');
      setCustomCredits('');
      setChangeOrderPrice('');
      setIsCustomPlan(false);
      cardElement.clear();
    } catch (error) {
      console.error('Error during signup:', error);
      setErrorMessage(error.message || 'An error occurred during signup. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="sales-signup-form">
      <div className="form-group">
        <label htmlFor="companyName">Company Name:</label>
        <input
          type="text"
          id="companyName"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="firstName">First Name:</label>
        <input
          type="text"
          id="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="lastName">Last Name:</label>
        <input
          type="text"
          id="lastName"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="phoneNumber">Phone Number:</label>
        <input
          type="tel"
          id="phoneNumber"
          value={phoneNumber}
          onChange={handlePhoneChange}
          placeholder="(123) 456-7890"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="plan">Plan:</label>
        <select
          id="plan"
          value={planType === 'custom' ? 'custom' : planId}
          onChange={(e) => {
            const selectedValue = e.target.value;
            if (selectedValue === 'custom') {
              setPlanType('custom');
              setPlanId('');
              setIsCustomPlan(true);
            } else {
              setPlanType('predefined');
              setPlanId(selectedValue);
              setIsCustomPlan(false);
            }
          }}
          required
        >
          <option value="">Select a plan</option>
          {plans.map((p) => (
            <option key={p.id} value={p.id}>
              {p.name} - ${p.price}/{p.interval} ({p.creditsPerMonth} credits/month)
            </option>
          ))}
          <option value="custom">Custom Plan</option>
        </select>
      </div>
      {isCustomPlan && (
        <>
          <div className="form-group">
            <label htmlFor="customCredits">Number of Credits per Month:</label>
            <input
              type="number"
              id="customCredits"
              value={customCredits}
              onChange={(e) => setCustomCredits(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="changeOrderPrice">Change Order Price per Credit ($):</label>
            <input
              type="number"
              id="changeOrderPrice"
              value={changeOrderPrice}
              onChange={(e) => setChangeOrderPrice(e.target.value)}
              required
              step="0.01"
              min="0"
            />
          </div>
          <p>Price: ${calculateCustomPlanPrice(customCredits, changeOrderPrice).toFixed(2)}/month</p>
        </>
      )}
      <div className="form-group">
        <label htmlFor="card-element">Credit or debit card</label>
        <div className="card-element">
          <CardElement id="card-element" />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="email">Billing Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <button type="submit" disabled={isLoading} className="submit-button">
        {isLoading ? 'Processing...' : 'Sign Up Customer'}
      </button>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
    </form>
  );
};

const StripeElementsWrapper = ({ isLiveAccount, children }) => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    setStripePromise(loadStripe(isLiveAccount ? STRIPE_LIVE_PUBLISHABLE_KEY : STRIPE_TEST_PUBLISHABLE_KEY));
  }, [isLiveAccount]);

  if (!stripePromise) {
    return null;
  }

  return <Elements stripe={stripePromise}>{children}</Elements>;
};

const SalesSignup = () => {
  const [isLiveAccount, setIsLiveAccount] = useState(true);

  const handleAccountTypeChange = (e) => {
    const newIsLiveAccount = e.target.checked;
    setIsLiveAccount(newIsLiveAccount);
    console.log(`Account type changed to: ${newIsLiveAccount ? 'Live' : 'Test'}`);
  };

  return (
    <div className="sales-signup-root">
      <div className="sales-signup-container">
        <div className="account-type-toggle">
          <label className="account-type-label">
            <input
              type="checkbox"
              checked={isLiveAccount}
              onChange={handleAccountTypeChange}
            />
            Live Account
          </label>
        </div>
        <p className="current-mode">Current mode: {isLiveAccount ? 'Live' : 'Test'}</p>
        <StripeElementsWrapper key={isLiveAccount ? 'live' : 'test'} isLiveAccount={isLiveAccount}>
          <SalesSignupForm isLiveAccount={isLiveAccount} />
        </StripeElementsWrapper>
      </div>
    </div>
  );
};

export default SalesSignup;
